$kendo-notification-padding-x: 0.75rem;
$kendo-notification-padding-y: 0.5rem;
$kendo-notification-border-width: 0;
$kendo-notification-border-radius: $border-radius-lg;
$kendo-notification-bg: color('secondary');
$kendo-notification-text: color('white');
$kendo-notification-theme-colors: (
    "primary": color('primary'),
    "secondary": color('secondary'),
    "tertiary": color('tertiary'),
    "info": color('tertiary'),
    "success": color('success'),
    "warning": color('warning'),
    "error": color('error'),
    "dark": color('secondary'),
    "light": color('secondary'),
    "inverse": color('secondary'),
);
