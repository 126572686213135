// ///
// /// Overrides
// ///

@at-root {
  @font-face {
    font-family: 'WebComponentsIcons';
    src: url(#{$kendo-icon-font-url}) format('woff2');
    font-weight: normal;
    font-style: normal;
    font-swap: swap;
  }
}


.k-loading {

  &-mask,
  &-image,
  &-color {
    border-radius: $border-radius-lg;
  }

  &-mask &-color {
    background-color: color('white');
    opacity: .9;
  }
}


.k-label {
  font-size: map-get($font-sizes, 's');
}

.k-label {
  color: color('text');
  font-weight: normal;
}

.k-upload {
  border: 0;

  .k-dropzone {
    $kendo-dropzone-border-width: 2px;

    @include set-border((width: 2px,
        style: dashed,
        color: color('lightgray'),
        radius: $default-border-radius,
      ));

    flex-direction: column-reverse;
    height: auto;
    ;
    padding: calc(1rem - #{$kendo-dropzone-border-width});
    line-height: $base-line-height;

    &.k-hover {
      &::after {
        content: 'Drag & drop your files here!';
        position: absolute;
        top: -$kendo-dropzone-border-width;
        right: -$kendo-dropzone-border-width;
        bottom: -$kendo-dropzone-border-width;
        left: -$kendo-dropzone-border-width;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: color('white');
        background: $kendo-upload-dropzone-hover-bg;
        border-radius: $default-border-radius;
      }
    }

    .k-dropzone-hint {
      margin-bottom: 1rem;
      line-height: 1rem;
      font-size: map-get($font-sizes, 'm');
      font-weight: bold;
      text-align: center;
      white-space: pre-wrap;
    }

    .k-upload-actions {
      >.k-icon:not([class*="k-i-"]) {
        display: inline-flex;
        width: 100%;
        height: 1rem;
        margin: 0;
        line-height: $base-line-height;

        &::before {
          content: 'Supported file type is .*';
          font-family: $base-font-family;
          font-size: map-get($font-sizes, 'm');
          font-weight: bold;
          color: color('red');
        }
      }
    }

    .k-upload-button {
      padding: 0;
      line-height: $base-line-height;
      color: color('tertiary');
      background: transparent;
      border: 0;
      box-shadow: none;

      &:hover {
        color: color('primary');
      }
    }
  }

  .k-upload-files {
    counter-reset: file-item;
    margin: 0.75rem 0 0 0;
    font-size: map-get($font-sizes, 's');
    border: 0;
    overflow-x: hidden;
    overflow-y: auto;

    .k-file-progress {
      .k-file-validation-message {
        display: none;
      }
    }

    >.k-file {
      padding: 0;
      border: 0;

      &::before {
        align-self: center;
        counter-increment: file-item;
        content: counter(file-item) '. ';
        display: inline-block;
        width: 2ch;
        margin: 0 $kendo-icon-spacing 0 0;
        font-size: map-get($font-sizes, 'xs');
        text-align: right;
        color: color('primary');
      }

      >.k-file-single {

        .k-file-extension-wrapper,
        .k-file-invalid-extension-wrapper {
          display: none;
        }

        >.k-file-name-size-wrapper,
        .k-file-extension-wrapper+.k-file-name-size-wrapper,
        .k-file-invalid-extension-wrapper+.k-file-name-size-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          min-height: 1.5rem;
          margin-left: 0;
        }


        .k-progressbar {
          display: none;
          // width: calc(100% - 1rem);
        }

        .k-upload-actions {
          align-self: flex-start;

          .k-upload-pct {
            display: none;
            margin: 0 0 0 $kendo-icon-spacing;
          }
        }
      }
    }

    .k-upload-action {
      width: 1rem;
      height: 1rem;
      padding: 0;
    }
  }

  .k-file-icon-wrapper {
    margin-right: $kendo-icon-spacing;

    .k-file-icon {
      font-size: 1rem;
    }
  }


  .k-action-buttons {
    margin: 0.75rem 0 0 0;
    padding: 0;
    border: 0;
  }
}

.k-progressbar .k-selected {
  border-color: color('primary');
  background-color: color('primary');
}

.k-cell-inner {
  .k-link {
    padding: 0.25rem 0;
  }
}

.k-sort-icon {
  color: color("primary");
}

.k-table-md .k-table-th {
  padding: 0.25rem 0;
}

.k-table-md .k-table-td {
  padding: 0.5rem 0.5rem 0.5rem 0;
  font-size: 16px;
}

.k-grid {
  table {
    border-collapse: collapse;
  }

  .k-table-thead {
    border: 0;
    background: none;
  }

  .k-header {
    font-weight: 500;
    font-size: 15px;
    border: 0;
  }

  .k-filter-row th {
    border: 0;
  }

  tr>td {
    padding-bottom: $kendo-grid-padding-y - $kendo-grid-cell-horizontal-border-width;
    padding-right: $kendo-grid-padding-y !important;
  }

  &-pager {
    border: 0;

    .k-pager-input .k-numerictextbox {
      width: 5ch;
      height: auto;
      padding: 0;
      line-height: 1;
      text-align: center;

      .k-input-inner {
        border: 0;
        text-align: center;
        width: 3em;
        line-height: 15px;
        padding: 0;
        height: 1rem;
      }
    }
  }

  .k-pager-numbers-wrap {
    display: flex;
  }

  .k-pager-info {
    display: inline-flex;
    justify-content: flex-start;
    font-weight: 400;
    font-size: 14px;
  }

  .k-filtercell {
    margin-right: 0.5rem;

    input {
      @include set-background((image: url('data:image/svg+xml;utf8,<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M15.853 16.56A9.458 9.458 0 019.5 19C4.257 19 0 14.743 0 9.5S4.257 0 9.5 0 19 4.257 19 9.5c0 2.442-.923 4.67-2.44 6.353l7.44 7.44-.707.707-7.44-7.44zM9.5 1C14.191 1 18 4.809 18 9.5S14.191 18 9.5 18 1 14.191 1 9.5 4.809 1 9.5 1z"/></svg>'),
          repeat: no-repeat,
          position: right 10px center,
          size: 12px,
        ));

      height: 1rem;
      padding-top: 0;
      padding-bottom: 0;
      font-weight: 300;
      min-width: none;
      width: 100%;

      &:focus {
        background-image: none;
      }
    }


    &-operator {
      display: none;
    }
  }
}

.k-panelbar {
  >.k-progressbar-chunk {

    &.k-active>.k-link,
    &.k-expanded>.k-link {
      font-weight: bold;
    }

    >.k-link {
      font-size: map-get($font-sizes, 'l');
      font-weight: bold;
      cursor: pointer;

      &.k-selected:hover,
      &.k-selected.k-hover {
        background-color: transparent;
      }
    }
  }
}


.k-window.k-dialog {
  overflow: hidden;

  .k-window-title.k-dialog-title {
    line-height: $base-line-height;
    font-weight: 500;
    color: color('tertiary');

    &:before {
      color: color('secondary');
    }
  }

  .k-dialog-button-layout-stretched {
    border-color: color('lightgray');
  }
}


.k-notification {
  position: relative;
  padding: 1rem;
  font-size: map-get($font-sizes, 'l');
  line-height: $base-line-height;
  display: flex;
  align-items: center;

  >.k-icon {
    margin-right: $kendo-icon-spacing;
    font-size: $base-line-height;
  }

  >.k-i-close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    margin: 0;
    font-size: $base-font-size;
    border: 0;

    &:hover {
      border: 0;
    }
  }


  &-content {
    margin-right: 1.5rem;
    white-space: pre-wrap;
  }
}

.k-chip {
  &.k-chip-filled:not(.k-chip-outline) {
    border: none;
  }
}

.k-chip-md {
  padding: 4px;
  font-size: 14px;
}

.k-chip-icon {
  margin-left: 8px;
  flex: 1 0 auto;
}

.k-treeview {
  font-size: 16px;
}

// Set bigger z-index than it is on k-overlay
// to solve the issue with assign roles on user management page
.k-list-container {
  z-index: 10002;
}


.k-animation-container {
  z-index: 10003 !important;
}

.k-dropdown .k-dropdown-wrap,
.k-dropdowntree .k-dropdown-wrap {
  cursor: pointer;
}

.k-upload .k-actions,
.k-upload .k-action-buttons {
  border-top: 0;
}

.k-grid-header .k-header {
  font-weight: 500;
  font-size: 15px;
}

.k-grid-pager {
  display: flex;
  padding-left: 0;
  padding-right: 0;
}

.k-pager-md {
  padding-block: 8px;
  padding-inline: 0px;
  background: none;
}

.k-checkbox-md {
  width: 16px;
  height: 16px;
  border-radius: 4px;
}

.k-radio-md {
  width: 16px;
  height: 16px;
}

th.k-table-th.k-header.k-sorted {
  background: none;
}

.k-pager-md .k-pager-numbers-wrap .k-pager-input.k-label {
  margin-left: 0;
  margin-right: 0;
}

.k-chip-md {
  border-radius: 12px;
}

.k-input-md .k-input-inner,
.k-picker-md .k-input-inner {
  padding: .25rem .5rem;
  height: 1.5rem;
}

.k-picker-solid {
  background: #fff;
}

.k-datepicker .k-button {
  background: #fff;
  border: 0;
}

.k-input-inner {
  font-weight: 300;
}

.k-chip-list {
  .k-chip-content {
    padding: 0;
  }

  .k-chip-solid-base {
    transition: all .15s ease-in-out 0s;
    background-color: #fff;
    background-image: none;
    border-color: color("secondary");
    box-shadow: none;
    color: color("secondary");
    align-items: center;
    cursor: default;
    display: inline-flex;
    flex-direction: row;
    gap: 8px;
    line-height: inherit;
    margin: 0 0 0 0.125rem;
    min-height: 28px;
    border-radius: 12px;
    padding: 0.05208rem 0.5rem;
    white-space: normal;
    font-weight: 300;

    .k-chip-actions {
      opacity: .6;
      cursor: pointer;
    }

    &:hover {
      color: #fff;
      background-color: color("secondary");
    }
  }
}

.k-chip-solid-warning {
  border-color: rgba(color('orange'), 1);
  background-color: color('highlight');
}

textarea.k-input-inner {
  margin: 0;
  height: auto;
  min-height: 1.5rem;
  overflow-y: auto;
  padding: 0.25rem 0.5rem;
  resize: vertical;
}

.k-form-buttons {
  margin-top: 0;
}

.k-picker-md {
  border-color: color("primary");
  color: color("text");

  &:hover {
    background-color: #fff;
  }

  .k-input-inner {

    height: auto;
    font-weight: 300;
  }
}

.k-multiselect {
  .k-input-inner {
    height: auto;
  }
}

.k-treelist-toggle {
  margin-block: -4px;
  padding: 4px;
}

.k-chip-content:last-child {
  -webkit-margin-end: 4px;
  margin-inline-end: 4px;
}

.k-chip-content:first-child {
  -webkit-margin-start: 4px;
  margin-inline-start: 4px;
}

.k-selected {
  background-color: blue;
}